import { render, staticRenderFns } from "./PhotoCellRenderer.vue?vue&type=template&id=318a41e8&scoped=true&"
import script from "./PhotoCellRenderer.vue?vue&type=script&lang=js&"
export * from "./PhotoCellRenderer.vue?vue&type=script&lang=js&"
import style0 from "./PhotoCellRenderer.vue?vue&type=style&index=0&id=318a41e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318a41e8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src277794545/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('318a41e8')) {
      api.createRecord('318a41e8', component.options)
    } else {
      api.reload('318a41e8', component.options)
    }
    module.hot.accept("./PhotoCellRenderer.vue?vue&type=template&id=318a41e8&scoped=true&", function () {
      api.rerender('318a41e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project-archive/page/codes/grid/PhotoCellRenderer.vue"
export default component.exports